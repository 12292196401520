import React from "react";

export default function UserDashboardRecent() {
  return (
    <div>
      <p className=" font-semibold">Recent Applicants</p>
      <p className=" text-sm text-gray-500 mt-5">No applicants found.</p>
    </div>
  );
}
